import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { centerBlock } from 'theme/mixins'
import Logo from 'assets/svgs/logo.svg'
import LangSwitcher from 'components/LangSwitcher'
import { useLocation } from 'react-router-dom'
import router from 'router'

const Header = () => {
  const location = useLocation()

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <FigureLogo>
            <img src={Logo} alt='Coinbaq' />
          </FigureLogo>
          {location.pathname === router.ankietaKsiegowoscKadry && <LangSwitcher absolute />}
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.header`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const InnerWrapper = styled.header`
  position: relative;
`

const FigureLogo = styled.figure`
  ${centerBlock}
  max-width: 21.5rem;
  width: 100%;
`
