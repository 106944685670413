import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as typo from 'theme/typography'
import DefinitionsEn from './DefinitionsEn'
import DefinitionsPl from './DefinitionsPl'

const PepDefinitions = () => {
  const { i18n } = useTranslation()

  return (
    <Article className='t-content'>
      {i18n.language === 'pl' && <DefinitionsPl />}
      {i18n.language === 'en' && <DefinitionsEn />}
    </Article>
  )
}

export default PepDefinitions

const Article = styled.article`
  ${typo.ParagraphS}

  overflow: auto;
  margin-right: -1.5rem;
  padding-right: 1rem;

  &::-webkit-scrollbar,
  textarea::-webkit-scrollbar,
  iframe::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track,
  textarea::-webkit-scrollbar-track,
  iframe::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.border};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb,
  textarea::-webkit-scrollbar-thumb,
  iframe::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.blue};
    border-radius: 4px;
  }
`
