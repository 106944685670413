import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { breakpoints } from 'theme/breakpoints'
import HeadingBar from 'components/layouts/HeadingBar'
import Button from 'components/Button'
import router from 'router'

const PodziekowanieView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <HeadingBar title={t('page.thanks.accountingAndHumanResources.title')} />
      <Wrapper>
        <Container>
          <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='45' cy='45' r='45' fill='#3973B3' />
            <circle cx='45' cy='45' r='45' fill='#3973B3' />
            <path
              d='M39.9627 54.0319L31.6688 45.738L28.1074 49.2993L39.9627 61.1547L64.4113 36.7061L60.85 33.1447L39.9627 54.0319Z'
              fill='#FBFBFB'
            />
          </svg>
          <h1 className='t-heading t-heading--xxl'>{t('page.thanks.accountingAndHumanResources.heading')}</h1>
          <p className='t-paragraph t-paragraph--xxl'>{t('page.thanks.accountingAndHumanResources.paragraph')}</p>
          <p className='t-paragraph t-paragraph--xxl'>{t('page.thanks.accountingAndHumanResources.paragraph2')}</p>
          <ActionsWrapper>
            <Button onClick={() => navigate(router.home)}>{t('buttons.boBack')}</Button>
          </ActionsWrapper>
        </Container>
      </Wrapper>
    </>
  )
}

export default PodziekowanieView

const Wrapper = styled.section`
  padding-top: 8rem;
  padding-bottom: 20rem;
  background-color: ${(props) => props.theme.colors.bg};
  text-align: center;
  flex: 1;

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  svg {
    margin-bottom: 4rem;
  }

  h1 {
    color: ${(props) => props.theme.colors.blue};
    margin-bottom: 4rem;
  }

  p {
    color: ${(props) => props.theme.colors.gray};
    margin-bottom: 0;
  }

  p + p {
    margin-top: 1rem;
  }
`

const ActionsWrapper = styled.div`
  margin-top: ${(props) => props.theme.gaps.xxl};
`
