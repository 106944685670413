import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import { breakpoints } from 'theme/breakpoints'
import HeadingBar from 'components/layouts/HeadingBar'
import PrivacyPL from './Privacy/PrivacyPL'
import PrivacyEn from './Privacy/PrivacyEn'

const PolitypaPrywatnościView = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <HeadingBar title={t('common.privacyPolicy')} />
      <Wrapper className='t-content'>
        <Container>
          {i18n.language === 'pl' && <PrivacyPL />}
          {i18n.language === 'en' && <PrivacyEn />}
        </Container>
      </Wrapper>
    </>
  )
}

export default PolitypaPrywatnościView

const Wrapper = styled.section`
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: ${(props) => props.theme.colors.bg};

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`
