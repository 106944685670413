import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { centerBlock } from 'theme/mixins'
import LogoWhite from 'assets/images/logo-white.png'
import router from 'router'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <FigureLogo>
            <img src={LogoWhite} alt='Coinbaq' />
          </FigureLogo>
          <Link target='_blank' className='t-paragraph t-paragraph--m' to={router.politykaPrywatnosci}>
            {t('common.privacyPolicy')}
          </Link>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.footer`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: ${(props) => props.theme.colors.dark};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .t-paragraph {
    margin-bottom: 0;
  }
`

const FigureLogo = styled.figure`
  ${centerBlock}
  max-width: 15rem;
  width: 100%;
`
