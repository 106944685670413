const PrivacyPL = () => {
  return (
    <>
      <p>
        <strong>
          Informacja o zbieraniu i przetwarzaniu danych osobowych osób fizycznych przez Coinbaq Business Solutions Sp. z
          o.o.
        </strong>
      </p>

      <p>
        Wobec treści przepisów o ochronie danych osobowych, w tym rozporządzenia Parlamentu Europejskiego i Rady (UE)
        2016/679 z dnia 27.04.2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO) pragniemy
        poinformować Państwa o podstawach prawnych przetwarzania danych osobowych osób fizycznych, sposobach ich
        zbierania i wykorzystywania, a także o prawach osób fizycznych, których dane przetwarzamy. Do tego celu posłuży
        nam niniejszy dokument, który ma zastosowanie gdy spółka Coinbaq Business Solutions Sp. z o.o. pełni rolę
        administratora danych osobowych osób fizycznych i przetwarza dane osobowe zarówno te pozyskane bezpośrednio od
        osoby, której dane dotyczą, jak i te pozyskane z innych źródeł.
      </p>

      <br />

      <p>
        <strong>I. Administrator danych osobowych:</strong>
      </p>

      <p>
        Administratorem Twoich danych osobowych jest Coinbaq Business Solutions Sp. z o.o. z siedzibą w Katowicach,
        adres: ul. Sobieskiego 2, 40-082 Katowice, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego
        prowadzonego przez Sąd Rejonowy Katowice Wschód w Katowicach, Wydział VIII Gospodarczy Krajowego Rejestru
        Sądowego, pod numerem KRS: 0000602179, NIP: 6342856730, REGON: 363723042, o kapitale zakładowym 100.000 zł.
      </p>

      <p>Kontakt z nami możliwy jest za pośrednictwem:</p>

      <ul>
        <li>tradycyjnej korespondencji na podany wyżej adres</li>
        <li>
          korespondencji elektronicznej na adres email:{' '}
          <a href='mailto:biuro@coinbaq-solutions.pl'>biuro@coinbaq-solutions.pl</a>
        </li>
        <li>
          telefonicznie pod numerem telefonu: <a href='tel:+48506104104'>+48 506 104 104</a>
        </li>
      </ul>

      <br />

      <p>
        <strong>II. Podstawa prawna przetwarzania danych osobowych osoby fizycznej</strong>
      </p>

      <p>
        <strong>A. Jeśli skontaktujesz się z nami w celu:</strong>
      </p>

      <ul>
        <li>uzyskania informacji o naszej ofercie lub jeśli zechcesz otrzymywać nasz newsletter,</li>
        <li>podzielenia się uwagami dotyczącymi naszych usług,</li>
        <li>zawarcia z nami umowy w zakresie prowadzonej przez nas działalności gospodarczej.</li>
      </ul>

      <p>
        Zgromadzimy Twoje dane osobowe, takie jak: imię i nazwisko, stanowisko, adres firmy, adres e-mail oraz numer
        telefonu/faksu. Podanie danych osobowych jest dobrowolne ale konieczne dla przesłania oferty, newsletter,
        odpowiedzi na zapytanie, zawarcia umowy lub po prostu kontaktu zwrotnego.
      </p>

      <p>Przetwarzając Twoje dane osobowe w powyższych sytuacjach opieramy się na:</p>

      <ul>
        <li>
          naszym usprawiedliwionym interesie jako administratora danych (np. w zakresie tworzenia bazy danych,
          prowadzenia naszych kampanii reklamowych i innych działań marketingowych, zabezpieczeniu dokumentacji na
          potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń),
        </li>
        <li>
          wyrażeniu przez Ciebie zgody, np. w ten sposób, że zaznaczyłeś okienko wyboru podczas wyrażania zgody na
          otrzymywanie newsletter na naszej stronie internetowej; albo podałeś swój email w miejscu przeznaczonym na
          zapisanie się na otrzymywanie newsletter; albo wysłałeś do nas email z zapytaniem, co oznacza, że możemy
          przetwarzać dane, które nam podałeś w celu udzielenia odpowiedzi; albo w inny sposób wskazałeś, że w danych
          sytuacji akceptujesz proponowane przetwarzanie Twoich danych,
        </li>
        <li>konieczności zawarcia umowy i wypełnienia jej warunków.</li>
      </ul>

      <p>
        <strong>B. Jeśli jesteś:</strong>
      </p>

      <ul>
        <li>naszym klientem,</li>
        <li>naszym potencjalnym klientem,</li>
        <li>osobą fizyczną związaną z naszym klientem niebędącym osobą fizyczną (np. pracownikiem),</li>
        <li>naszym dostawcą towarów lub usług,</li>
        <li>naszym potencjalnym dostawcą towarów lub usług,</li>
        <li>
          osobą fizyczną związaną z naszym dostawcą towarów lub usług niebędącym osobą fizyczną (np. pracownikiem).
        </li>
      </ul>

      <p>
        Zgromadzimy Twoje dane osobowe takie jak: imię i nazwisko, nazwę pracodawcy, stanowisko osoby kontaktowej, numer
        telefonu, adres e-mail lub inne służbowe dane kontaktowe, a także dane dotyczące płatności, tj. numery rachunków
        bankowych, nazwę banku. Podanie danych osobowych jest dobrowolne ale konieczne dla zawarcia i wykonania umowy,
        dokonania płatności.
      </p>

      <p>Przetwarzając Twoje dane osobowe w powyższych sytuacjach opieramy się na:</p>

      <ul>
        <li>
          naszym usprawiedliwionym interesie jako administratora danych (np. w zakresie tworzenia bazy danych,
          prowadzenia naszych kampanii reklamowych i innych działań marketingowych, zabezpieczeniu dokumentacji na
          potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń),
        </li>
        <li>
          wyrażeniu przez Ciebie zgody, np. w ten sposób, że zaznaczyłeś okienko wyboru podczas wyrażania zgody na
          otrzymywanie newslettera na naszej stronie internetowej; albo podałeś swój email w miejscu przeznaczonym na
          zapisanie się na otrzymywanie newsletter; albo wysłałeś do nas email z zapytaniem, co oznacza, że możemy
          przetwarzać dane, które nam podałeś w celu udzielenia odpowiedzi; albo w inny sposób wskazałeś, że w danych
          sytuacji akceptujesz proponowane przetwarzanie Twoich danych,
        </li>
        <li>
          informacyjnego lub też na innym oświadczeniu bądź zachowaniu, które w danym kontekście jasno wskazuje, że
          osoba, której dane dotyczą, zaakceptowała proponowane przetwarzanie jej danych osobowych,
        </li>
        <li>konieczności zawarcia umowy i wypełnienia jej warunków,</li>
        <li>obowiązków wynikających z prawa (np. obowiązku wykonywania rozliczenia finansowego).</li>
      </ul>

      <br />

      <p>
        <strong>III. Automatyczne przetwarzanie danych osobowych osoby fizycznej</strong>
      </p>

      <p>
        Twoje dane osobowe, które zostały przez nas zgromadzone, nie są przedmiotem procesu zautomatyzowanego
        podejmowania decyzji, tj. profilowania.
      </p>

      <p>
        <strong>Informacja dotycząca plików cookies</strong>
      </p>

      <p>
        Administrator strony, na której się znajdujesz, przetwarza informacje zawarte w plikach cookies. W przypadku
        zbierania informacji o użytkownikach zbierane są wyłącznie anonimowe dane w postaci zbiorczej, nie obejmujące
        danych osobowych. Plik cookie to mały plik tekstowy zapisywany na komputerze użytkownika podczas odwiedzania
        witryny korzystającej z plików cookie. Podczas następnej wizyty na tej stronie może odczytać plik cookie i
        wyświetlić strony zgodnie z ustawieniami. Niniejsza strona korzysta czasem z plików cookie, ale tylko dla
        ułatwienia korzystania z niej. Jeśli nie akceptujesz ciasteczek przechowywanych na Twoim komputerze, możesz
        zmienić ustawienia w swojej przeglądarce, aby nie zapisywała plików cookie.
      </p>

      <p>
        Podmiotem zamieszczającym na urządzeniu końcowym użytkownika niniejszej witryny pliki cookies oraz uzyskującym
        do nich dostęp jest operator witryny
      </p>

      <br />

      <p>
        <strong>IV. Okres przechowywania danych</strong>
      </p>

      <p>
        Czas, przez jaki będziemy przetwarzać Twoje dane osobowe, uzależniony jest od podstawy prawnej stanowiącej
        przesłankę przetwarzania Twoich danych osobowych przez nas jako administratora, tj.:
      </p>

      <ul>
        <li>
          gdy przetwarzamy Twoje dane osobowe na podstawie zgody, okres przetwarzania trwa do momentu gdy zgodę
          wycofasz,
        </li>
        <li>
          gdy przetwarzamy Twoje dane osobowe na podstawie naszego uzasadnionego interesu jako administratora danych,
          okres przetwarzania trwa do momentu ustania tego interesu (np. okres przedawnienia roszczeń cywilnoprawnych)
          lub do momentu Twojego sprzeciwu wobec dalszego przetwarzania, o ile sprzeciw taki zgodnie z prawem Ci
          przysługuje,
        </li>
        <li>
          gdy przetwarzamy Twoje dane osobowe, na podstawie obowiązków wynikających z przepisów prawa, okresy
          przetwarzania danych w tym celu określają te przepisy,
        </li>
        <li>
          w przypadku braku konkretnych wymogów prawnych lub umownych, podstawowy okres przechowywania Twoich danych
          przez nas jako administratora wynosi maksymalnie 10 lat.
        </li>
      </ul>

      <br />

      <p>
        <strong>V. Odbiorcy danych</strong>
      </p>

      <p>
        Twoje dane osobowe możemy w pewnych sytuacjach przekazywać innym podmiotom, jeśli będzie to koniecznie, abyśmy
        mogli wywiązać się z umowy, którą zawarliśmy z Tobą lub z podmiotem, który reprezentujesz. Zawsze kiedy
        przekazujemy Twoje dane, robimy to tylko w niezbędnym zakresie.
      </p>

      <p>Będziemy przekazywać dane wyłącznie trzem grupom podmiotów:</p>

      <ul>
        <li>
          osobom upoważnionym przez nas, naszym pracownikom i współpracownikom, którzy muszą mieć dostęp do danych, aby
          wykonywać swoje obowiązki
        </li>
        <li>podmiotom przetwarzającym, którym zlecimy to zadanie</li>
        <li>
          innym odbiorcom danych np. kurierom, bankom, pośrednikom płatności, ubezpieczycielom, kancelariom prawnym
        </li>
      </ul>

      <p>
        Przekazanie Twoich danych innym podmiotom może też wynikać z przepisów prawa, dotyczy to np. organów
        administracji publicznej.
      </p>

      <p>
        Przekazywanie danych następuje w obrębie państw należących do Europejskiego Obszaru Gospodarczego („EOG”) i
        państw, w których obowiązują przepisy określające szczególną ochronę danych osobowych.
      </p>

      <br />

      <p>
        <strong>VI. Prawa przysługujące osobom fizycznym, których dane przetwarzamy</strong>
      </p>

      <p>
        Osoby fizyczne mają określone prawa dotyczące swoich danych osobowych, a Coinbaq Business Solutions Sp. z o.o.,
        jako administrator danych odpowiada za realizację tych praw zgodnie z obowiązującymi przepisami prawa.
      </p>

      <ol type='A'>
        <li>
          <strong>Prawo dostępu do danych przysługujące osobie fizycznej, której dane dotyczą</strong> – masz prawo
          uzyskać od nas informacje, czy przetwarzamy Twoje dane osobowe, a jeżeli tak, to wtedy masz prawo uzyskać
          dostęp do Twoich danych osobowych oraz uzyskać wszelkie informacje związane z procesem ich przetwarzania.
        </li>

        <li>
          <strong>Prawo do sprostowania danych osobowych osoby fizycznej</strong> – masz prawo żądać od nas
          niezwłocznego sprostowania Twoich danych osobowych, lub uzupełnienia danych niekompletnych.
        </li>

        <li>
          <strong>Prawo do usunięcia danych osoby fizycznej, tzw. Prawo do bycia zapomnianym</strong> – masz prawo
          zażądać od nas niezwłocznego usunięcia Twoich danych osobowych. Twoje dane usuniemy, jeśli zachodzi ku temu
          przesłanka przewidziana w przepisach, tj. np. gdy Twoje dane nie są już niezbędne do celu, dla którego zostały
          zebrane; gdy wycofasz swoją zgodę na przetwarzanie danych osobowych; gdy zgłosisz sprzeciw wobec przetwarzania
          Twoich danych; gdy Twoje dane będą przetwarzane niezgodnie z prawem.
        </li>

        <li>
          <strong>
            Prawo do ograniczenia przetwarzania lub wniesienia sprzeciwu wobec przetwarzania danych osoby fizycznej
          </strong>
          – masz prawo zażądać od nas ograniczenia przetwarzania Twoich danych osobowych lub wniesienia sprzeciwu wobec
          przetwarzania Twoich danych osobowych w dowolnej chwili, ze względu na Twoją szczególną sytuację, chyba że
          przetwarzanie jest zgodne z przepisami prawa.
        </li>

        <li>
          <strong>Prawo do cofnięcia zgody na przetwarzanie danych osoby fizycznej</strong> – jeśli przetwarzamy Twoje
          dane osobowe na podstawie zgody, której nam udzieliłeś, informujemy Cię, że masz prawo w dowolnej chwili zgodę
          wycofać. Informujemy Cię także, że wycofanie zgody nie będzie miało wpływu na zgodność z prawem przetwarzania,
          którego dokonano na podstawie zgody przed jej cofnięciem.
        </li>

        <li>
          <p>
            <strong>Prawo do przenoszenia danych osoby fizycznej</strong> – możesz zażądać przeniesienia Twoich danych
            osobowych, gdy ich przetwarzanie odbywa się na podstawie Twojej zgody lub umowy zawartej z Tobą oraz gdy
            przetwarzanie to odbywa się w sposób automatyczny.
          </p>

          <p>
            Jeśli chcesz skorzystać z któregokolwiek prawa wynikającego z przepisów o ochronie danych osobowych
            skontaktuj się z nami w jeden z poniższych sposobów:
          </p>

          <ul>
            <li>
              tradycyjna korespondencja na adres Coinbaq Business Solutions Sp. z o.o. ul. Sobieskiego 2, 40-082
              Katowice,
            </li>
            <li>
              korespondencja elektroniczna na adres email:{' '}
              <a href='mailto:biuro@coinbaq-solutions.pl'>biuro@coinbaq-solutions.pl</a>
            </li>
          </ul>
        </li>

        <li>
          <strong>Prawo do wniesienia skargi</strong> – jeśli jesteś osobą fizyczną, której dane osobowe przetwarzamy,
          masz prawo wnieść skargę do organu nadzorczego, którym jest Generalny Inspektor Ochrony Danych Osobowych
          (adres: Generalny Inspektor Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).
        </li>
      </ol>

      <br />

      <p>
        <strong>VII. Zmiany Polityki prywatności</strong>
      </p>

      <p>
        Informujemy Cię, że niniejszy dokument może zostać zmodyfikowany w związku ze zmianą przepisów prawa, z nowymi
        wytycznymi organów odpowiedzialnych za nadzór nad procesami ochrony danych osobowych, praktykami stosowanymi w
        obszarze ochrony danych osobowych. Zmiany dokumentu mogą także nastąpić na skutek zmiany sposobów, celów lub
        podstaw prawnych przetwarzania przez nas danych osobowych.
      </p>

      <p>
        Każdą aktualizację dokumentu będziemy publikować do Twojej wiadomości, aktualna treść obowiązuje od 25.05.2018r.
      </p>
    </>
  )
}

export default PrivacyPL
