import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import translationPL from 'translations/pl/translation.json'
import translationEn from 'translations/en/translation.json'

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      pl: { translation: translationPL },
      en: { translation: translationEn },
    },
    lng: 'pl',
    // fallbackLng: 'pl',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
