import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Formik, Form } from 'formik'
import { Col, Container, Row } from 'react-bootstrap'
import { ankietaOswiadczeniePepSchema } from 'utils/validations'
import { breakpoints } from 'theme/breakpoints'
import { centerBlock } from 'theme/mixins'
import { ScrollToError } from 'components/ScrollToError'
import * as typo from 'theme/typography'
import * as options from 'utils/config'
import router from 'router'
import HeadingBar from 'components/layouts/HeadingBar'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Logo from 'assets/svgs/logo.svg'
import TextInput from 'components/Forms/Inputs/TextInput'
import PepDefinitions from 'components/Definitions/PepDefinitions'
import Button from 'components/Button'
import DocumentDatePicker from 'components/Forms/Inputs/DocumentDatePicker'
import moment from 'moment'

const OswiadczeniePepView = () => {
  const { t, i18n } = useTranslation()
  const [infoModalState, setInfoModalState] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    setLoading(true)

    axios
      .post(options.endpoints.oswiadczeniePep, {
        ...values,
        date: moment(values.date).format('DD.MM.YYYY'),
      })
      .then((res) => {
        console.log(res)
        navigate(router.dziekujemyOswiadczenie)
      })
      .catch((err) => {
        console.log(err)
        setError(t('common.genericError'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Loading show={loading} />
      <Modal title={t('common.error')} show={error} onClose={() => setError(false)}>
        {error}
      </Modal>
      <Modal title={t('common.definitions')} show={infoModalState} onClose={() => setInfoModalState(false)}>
        <PepDefinitions />
      </Modal>
      <HeadingBar title={t('headers.pepStatement')} />
      <Wrapper>
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <Formik
                initialValues={{
                  date: '',
                  place: '',
                  clientFullName: '',
                  declarationPersonFullName: '',
                  pesel: '',
                  type: '',
                  idCardOrPassport: '',
                  isPep: '',
                  isPepAssociate: '',
                  isPepFamilly: '',
                }}
                validationSchema={ankietaOswiadczeniePepSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <>
                    <ScrollToError />
                    <StyledFormikForm>
                      <Page>
                        <FigureLogo>
                          <img src={Logo} alt='Coinbaq' />
                        </FigureLogo>
                        <div className='u-mb-xl top'>
                          <div
                            className='u-mb-s'
                            style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', gap: '1rem' }}
                          >
                            <div>
                              <Field
                                disabled={false}
                                label={t('page.pepStatement.date')}
                                name='date'
                                documentType
                                component={DocumentDatePicker}
                                $minWidth='15ch'
                              />
                            </div>
                            <div>
                              <Field
                                disabled={false}
                                label={t('page.pepStatement.place')}
                                name='place'
                                documentType
                                component={TextInput}
                                $minWidth='15ch'
                              />
                            </div>
                          </div>
                          <div className='u-mb'>
                            <Field
                              disabled={false}
                              label={t('page.pepStatement.clientFullName')}
                              name='clientFullName'
                              documentType
                              component={TextInput}
                            />
                          </div>
                          <div className='u-mb'>
                            <Field
                              disabled={false}
                              label={t('page.pepStatement.declarationPersonFullName')}
                              name='declarationPersonFullName'
                              documentType
                              component={TextInput}
                            />
                          </div>
                          <div className='u-mb'>
                            <Field
                              disabled={false}
                              label={t('page.pepStatement.pesel')}
                              name='pesel'
                              documentType
                              component={TextInput}
                            />
                          </div>
                        </div>
                        <div className='u-mb-m u-text-center title'>
                          <p className='t-paragraph t-paragraph--l t-paragraph--semi u-mb-s'>
                            {t('page.pepStatement.statementTitle')}
                          </p>
                          <p className='t-paragraph t-paragraph--l t-paragraph--semi u-mb'>
                            {[
                              t('page.pepStatement.clientTitle'),
                              t('page.pepStatement.beneficiaryTitle'),
                              t('page.pepStatement.authorizedTitle'),
                            ].map((pick, index) => (
                              <span key={pick}>
                                <PickSpan
                                  $picked={values.type ? values.type == pick : false}
                                  disabled={values.type ? values.type != pick : false}
                                  onClick={() => setFieldValue('type', pick)}
                                >
                                  {pick}
                                </PickSpan>
                                {index < 2 && ' / '}
                              </span>
                            ))}
                          </p>
                        </div>
                        <div className='u-mb-xl'>
                          <p className='t-paragraph u-lh-xxxl'>{t('page.pepStatement.paragraphOne')}</p>
                        </div>
                        <div className='u-mb-xl u-lh-xxxl'>
                          <MiddleWrapper>
                            {i18n.language === 'pl' && (
                              <>
                                <span>Ja,&nbsp;</span>
                                <span>niżej&nbsp;</span>
                                <span>podpisana/y&nbsp;</span>
                                <span>identyfikująca/cy&nbsp;</span> <span>się&nbsp;</span>
                                <span>nr&nbsp;</span>
                                <span>dowodu&nbsp;</span>
                                <span>osobistego&nbsp;</span> <span>(nr paszportowego)&nbsp;&nbsp;</span>{' '}
                              </>
                            )}
                            {i18n.language === 'en' && (
                              <>
                                <span>I,&nbsp;</span>
                                <span>the undersigned&nbsp;</span>
                                <span>signed&nbsp;</span>
                                <span>identifying myself&nbsp;</span> <span>with&nbsp;</span>
                                <span>an&nbsp;</span>
                                <span>identity&nbsp;</span>
                                <span>card number&nbsp;</span> <span>(passport number)&nbsp;&nbsp;</span>{' '}
                              </>
                            )}
                            <span style={{ lineHeight: '1.2', display: 'inline-flex', alignItems: 'center' }}>
                              <Field
                                disabled={false}
                                label=''
                                name='idCardOrPassport'
                                documentType
                                component={TextInput}
                                $minWidth='15ch'
                              />
                              &nbsp;
                            </span>
                            {i18n.language === 'pl' && (
                              <>
                                <span>oświadczam,&nbsp;</span>
                                <span>że&nbsp;</span>
                                <span>zapoznałam/em&nbsp;</span>
                                <span>się&nbsp;</span> <span>z&nbsp;</span>
                                <span>załączoną&nbsp;</span>
                                <span>definicją&nbsp;</span>
                                <span>poniższych&nbsp;</span> <span>pojęć*&nbsp;</span>
                                <span>i&nbsp;</span>
                                <span>oświadczam,&nbsp;</span>
                                <span>że:&nbsp;</span>
                              </>
                            )}
                            {i18n.language === 'en' && (
                              <>
                                <span>declare&nbsp;</span>
                                <span>that&nbsp;</span>
                                <span>I have&nbsp;</span>
                                <span>read&nbsp;</span>
                                <span>the attached&nbsp;</span>
                                <span>definition&nbsp;</span>
                                <span>of the&nbsp;</span>
                                <span>following&nbsp;</span> <span>terms*&nbsp;</span>
                                <span>and&nbsp;</span>
                                <span>declare&nbsp;</span>
                                <span>that:&nbsp;</span>
                              </>
                            )}
                          </MiddleWrapper>
                        </div>
                        <div className='u-mb-xl u-lh-l'>
                          <ol>
                            <li>
                              {[t('page.pepStatement.iAm'), t('page.pepStatement.iAmNot')].map((pick, index) => (
                                <span key={pick}>
                                  <PickSpan
                                    $picked={values.isPep ? values.isPep == pick : false}
                                    disabled={values.isPep ? values.isPep != pick : false}
                                    onClick={() => setFieldValue('isPep', pick)}
                                  >
                                    {pick}
                                  </PickSpan>
                                  {index < 1 ? ' / ' : ' '}
                                </span>
                              ))}
                              {t('page.pepStatement.isPep')}
                            </li>
                            <li>
                              {[t('page.pepStatement.iAm'), t('page.pepStatement.iAmNot')].map((pick, index) => (
                                <span key={pick}>
                                  <PickSpan
                                    $picked={values.isPepAssociate ? values.isPepAssociate == pick : false}
                                    disabled={values.isPepAssociate ? values.isPepAssociate != pick : false}
                                    onClick={() => setFieldValue('isPepAssociate', pick)}
                                  >
                                    {pick}
                                  </PickSpan>
                                  {index < 1 ? ' / ' : ' '}
                                </span>
                              ))}
                              {t('page.pepStatement.isPepAssociate')}
                            </li>
                            <li>
                              {[t('page.pepStatement.iAm'), t('page.pepStatement.iAmNot')].map((pick, index) => (
                                <span key={pick}>
                                  <PickSpan
                                    $picked={values.isPepFamilly ? values.isPepFamilly == pick : false}
                                    disabled={values.isPepFamilly ? values.isPepFamilly != pick : false}
                                    onClick={() => setFieldValue('isPepFamilly', pick)}
                                  >
                                    {pick}
                                  </PickSpan>
                                  {index < 1 ? ' / ' : ' '}
                                </span>
                              ))}
                              {t('page.pepStatement.isPepFamilly')}
                            </li>
                          </ol>
                        </div>
                        <div className='u-mb-xxl'>
                          <p className='t-paragraph u-lh-xxxl'>{t('page.pepStatement.paragraphTwo')}</p>
                        </div>
                        <SignaturesWrapper className='u-mb-xl'>
                          <div>
                            <p>{new Array(60).fill('.').join('')}</p>
                            <p className='t-paragraph t-paragraph--xs' style={{ marginTop: '0.5rem' }}>
                              {t('page.pepStatement.signatureAccounting')}
                            </p>
                          </div>
                          <div>
                            <p>{new Array(60).fill('.').join('')}</p>
                            <p className='t-paragraph t-paragraph--xs' style={{ marginTop: '0.5rem' }}>
                              {t('page.pepStatement.signatureSide')}
                            </p>
                          </div>
                        </SignaturesWrapper>
                        <Info>
                          <span onClick={() => setInfoModalState(true)}>{t('page.pepStatement.info')}</span>
                        </Info>
                      </Page>
                      <ActionsWrapper>
                        <Button type='submit'>{t('buttons.sendStatement')}</Button>
                      </ActionsWrapper>
                    </StyledFormikForm>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default OswiadczeniePepView

const Wrapper = styled.section`
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: ${(props) => props.theme.colors.bg};

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

const FigureLogo = styled.figure`
  ${centerBlock}
  max-width: 30rem;
  width: 100%;
  margin-bottom: ${(props) => props.theme.gaps.xl};
`

const StyledFormikForm = styled(Form)``

const Page = styled.section`
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 5rem 5rem;

  min-height: 500px;

  ol {
    margin-left: 5rem;
    counter-reset: list;

    li {
      position: relative;
      list-style: none;

      &:before {
        position: absolute;
        left: -3rem;
        content: counter(list) ') ';
        counter-increment: list;
      }
    }
    li + li {
      margin-top: 1.5rem;
    }
  }

  @media ${breakpoints.min.lg} {
    padding: 5rem 10rem;
  }
`

const ActionsWrapper = styled.div`
  margin-top: ${(props) => props.theme.gaps.xxl};
  text-align: center;
`

const PickSpan = styled.span`
  display: inline-block;
  user-select: none;
  cursor: pointer;
  outline: 1px dashed ${(props) => props.theme.colors.red};
  outline-offset: 2px;
  font-weight: 600;
  line-height: 1.2;

  ${({ disabled }) =>
    disabled &&
    css`
      text-decoration: line-through;
      outline: none;
      opacity: 0.25;
    `}

  ${({ $picked }) =>
    $picked &&
    css`
      outline: none;
    `}
`

const MiddleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    display: inline-block;
  }
  @media ${breakpoints.min.sm} {
  }
`

const SignaturesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  text-align: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media ${breakpoints.min.sm} {
    justify-content: space-between;
  }
`

const Info = styled.div`
  margin-top: 8rem;

  span {
    ${typo.ParagraphS}
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`
