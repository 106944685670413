const DefinitionsEn = () => {
  return (
    <>
      <p className='t-paragraph t-paragraph--m'>
        Wyciąg z ustawy z dnia 01.03.2018 r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu i
        rozporządzenia Ministra Finansów, Funduszy i Polityki regionalnej z dnia 27 lipca 2021, w sprawie wykazu
        krajowych stanowisk i funkcji publicznych będących eksponowanymi stanowiskami politycznymi (które wchodzi w
        życie z dniem 31 października 2021r.).
      </p>

      <p>
        <strong>&quot;Osoba zajmująca eksponowane stanowiska polityczne (PEP)&quot; </strong>- osoba pełniąca znaczące
        funkcje publiczne lub zajmująca znaczące stanowiska publiczne, w tym:
      </p>

      <ol>
        <li>Prezydent Rzeczypospolitej Polskiej;</li>

        <li>Prezes Rady Ministrów;</li>

        <li>wiceprezes Rady Ministrów;</li>

        <li>minister;</li>

        <li>sekretarz stanu;</li>

        <li>podsekretarz stanu;</li>

        <li>poseł;</li>

        <li>senator;</li>

        <li>poseł do Parlamentu Europejskiego;</li>

        <li>
          członek organu reprezentującego na zewnątrz partię polityczną zgłoszoną do ewidencji partii politycznych,
          prowadzonej przez Sąd Okręgowy w Warszawie;{' '}
        </li>

        <li>
          członek organu partii politycznej zgłoszonej do ewidencji partii politycznych, prowadzonej przez Sąd Okręgowy{' '}
        </li>

        <p>w Warszawie, uprawniony do zaciągania zobowiązań majątkowych;</p>

        <li>sędzia Trybunału Stanu;</li>

        <li>sędzia Sądu Najwyższego;</li>

        <li>sędzia Trybunału Konstytucyjnego;</li>

        <li>sędzia Naczelnego Sądu Administracyjnego;</li>

        <li>sędzia Sądu Apelacyjnego;</li>

        <li>Prezes Narodowego Banku Polskiego;</li>

        <li>członek Zarządu Narodowego Banku Polskiego;</li>

        <li>członek Rady Polityki Pieniężnej;</li>

        <li>
          pełnomocny przedstawiciel Rzeczypospolitej Polskiej w innym państwie lub przy organizacji międzynarodowej;{' '}
        </li>

        <li>chargés d&apos;affaires;</li>

        <li>
          oficer zajmujący stanowisko służbowe w siłach zbrojnych Rzeczypospolitej Polskiej zaszeregowane do stopni
          generalskich (admiralskich);{' '}
        </li>

        <li>pełnomocnik Ministra Obrony Narodowej powołany na podstawie odrębnej decyzji Ministra Obrony Narodowej;</li>

        <li>dyrektor, prezes przedsiębiorstwa państwowego lub inne stanowisko równoważne;</li>

        <li>przewodniczący rady nadzorczej przedsiębiorstwa państwowego;</li>

        <li>członek rady nadzorczej przedsiębiorstwa państwowego;</li>

        <li>
          prezes zarządu spółki z udziałem Skarbu Państwa, w której ponad połowa akcji albo udziałów należy do Skarbu
          Państwa lub innych państwowych osób prawnych;{' '}
        </li>

        <li>
          członek zarządu spółki z udziałem Skarbu Państwa, w której ponad połowa akcji albo udziałów należy do Skarbu{' '}
        </li>

        <p>Państwa lub innych państwowych osób prawnych;</p>

        <li>
          przewodniczący rady nadzorczej spółki z udziałem Skarbu Państwa, w której ponad połowa akcji albo udziałów
          należy do Skarbu Państwa lub innych państwowych osób prawnych;{' '}
        </li>

        <li>
          członek rady nadzorczej spółki z udziałem Skarbu Państwa, w której ponad połowa akcji albo udziałów należy do{' '}
        </li>

        <p>Skarbu Państwa lub innych państwowych osób prawnych;</p>

        <li>dyrektor generalny urzędu naczelnego organu państwa;</li>

        <li>dyrektor generalny urzędu centralnego organu państwa;</li>

        <li>dyrektor generalny urzędu wojewódzkiego;</li>

        <li>Szef Kancelarii Prezydenta Rzeczypospolitej Polskiej;</li>

        <li>Szef Kancelarii Prezesa Rady Ministrów;</li>

        <li>Szef Kancelarii Sejmu;</li>

        <li>Szef Kancelarii Senatu;</li>

        <li>wojewoda;</li>

        <li>wicewojewoda;</li>

        <li>marszałek województwa;</li>

        <li>inny niż marszałek województwa członek zarządu województwa;</li>

        <li>wójt, burmistrz, prezydent miasta;</li>

        <li>zastępca wójta, burmistrza i prezydenta miasta;</li>

        <li>starosta;</li>

        <li>inny niż starosta członek zarządu powiatu;</li>

        <li>Dyrektor Generalny Krajowego Ośrodka Wsparcia Rolnictwa;</li>

        <li>zastępca Dyrektora Generalnego Krajowego Ośrodka Wsparcia Rolnictwa;</li>

        <li>Dyrektor Generalny Lasów Państwowych;</li>

        <li>zastępca Dyrektora Generalnego Lasów Państwowych;</li>

        <li>Dyrektor Generalny Służby Więziennej;</li>

        <li>zastępca Dyrektora Generalnego Służby Więziennej;</li>

        <li>dyrektor generalny służby zagranicznej;</li>

        <li>dyrektor generalny urzędu przewodniczącego komitetu wchodzącego w skład Rady Ministrów;</li>

        <li>dyrektor Krajowej Szkoły Administracji Publicznej;</li>

        <li>zastępca dyrektora Krajowej Szkoły Administracji Publicznej;</li>

        <li>Dyrektor Polskiego Centrum Akredytacji;</li>

        <li>zastępca Dyrektora Polskiego Centrum Akredytacji;</li>

        <li>dyrektor Rządowego Centrum Bezpieczeństwa;</li>

        <li>zastępca dyrektora Rządowego Centrum Bezpieczeństwa;</li>

        <li>Dyrektor Transportowego Dozoru Technicznego;</li>

        <li>zastępca Dyrektora Transportowego Dozoru Technicznego;</li>

        <li>Generalny Dyrektor Dróg Krajowych i Autostrad;</li>

        <li>zastępca Generalnego Dyrektora Dróg Krajowych i Autostrad;</li>

        <li>Generalny Dyrektor Ochrony Środowiska;</li>

        <li>zastępca Generalnego Dyrektora Ochrony Środowiska;</li>

        <li>Generalny Inspektor Informacji Finansowej;</li>

        <li>Główny Geodeta Kraju;</li>

        <li>zastępca Głównego Geodety Kraju;</li>

        <li>Główny Inspektor Farmaceutyczny;</li>

        <li>zastępca Głównego Inspektora Farmaceutycznego;</li>

        <li>Główny Inspektor Jakości Handlowej Artykułów Rolno-Spożywczych;</li>

        <li>zastępca Głównego Inspektora Jakości Handlowej Artykułów Rolno-Spożywczych;</li>

        <li>Główny Inspektor Nadzoru Budowlanego;</li>

        <li>zastępca Głównego Inspektora Nadzoru Budowlanego;</li>

        <li>Główny Inspektor Ochrony Roślin i Nasiennictwa;</li>

        <li>zastępca Głównego Inspektora Ochrony Roślin i Nasiennictwa;</li>

        <li>Główny Inspektor Ochrony Środowiska;</li>

        <li>Zastępca Głównego Inspektora Ochrony Środowiska;</li>

        <li>Główny Inspektor Pracy;</li>

        <li>zastępca Głównego Inspektora Pracy;</li>

        <li>Główny Inspektor Sanitarny;</li>

        <li>zastępca Głównego Inspektora Sanitarnego;</li>

        <li>Główny Inspektor Transportu Drogowego;</li>

        <li>zastępca Głównego Inspektora Transportu Drogowego;</li>

        <li>Główny Lekarz Weterynarii;</li>

        <li>zastępca Głównego Lekarza Weterynarii;</li>

        <li>Główny Rzecznik Dyscypliny Finansowej;</li>

        <li>zastępca Głównego Rzecznika Dyscypliny Finansowej;</li>

        <li>Komendant Główny Państwowej Straży Pożarnej;</li>

        <li>zastępca Komendanta Głównego Państwowej Straży Pożarnej;</li>

        <li>Komendant Główny Policji;</li>

        <li>zastępca Komendanta Głównego Policji;</li>

        <li>Komendant Główny Straży Granicznej;</li>

        <li>zastępca Komendanta Głównego Straży Granicznej;</li>

        <li>Komendant Służby Ochrony Państwa;</li>

        <li>zastępca Komendanta Służby Ochrony Państwa;</li>

        <li>Naczelny Dyrektor Archiwów Państwowych;</li>

        <li>zastępca Naczelnego Dyrektora Archiwów Państwowych;</li>

        <li>Prezes Agencji Mienia Wojskowego;</li>

        <li>zastępca Prezesa Agencji Mienia Wojskowego;</li>

        <li>Prezes Agencji Restrukturyzacji i Modernizacji Rolnictwa;</li>

        <li>zastępca Prezesa Agencji Restrukturyzacji i Modernizacji Rolnictwa;</li>

        <li>Prezes Biura do spraw Substancji Chemicznych;</li>

        <li>Prezes Głównego Urzędu Miar;</li>

        <li>wiceprezes Głównego Urzędu Miar;</li>

        <li>Prezes Głównego Urzędu Statystycznego;</li>

        <li>wiceprezes Głównego Urzędu Statystycznego;</li>

        <li>Prezes Instytutu Pamięci Narodowej – Komisji Ścigania Zbrodni przeciwko Narodowi Polskiemu;</li>

        <li>zastępca Prezesa Instytutu Pamięci Narodowej – Komisji Ścigania Zbrodni przeciwko Narodowi Polskiemu;</li>

        <li>Prezes Kasy Rolniczego Ubezpieczenia Społecznego;</li>

        <li>zastępca Prezesa Kasy Rolniczego Ubezpieczenia Społecznego;</li>

        <li>Prezes Krajowego Zasobu Nieruchomości;</li>

        <li>zastępca Prezesa Krajowego Zasobu Nieruchomości;</li>

        <li>Prezes Najwyższej Izby Kontroli;</li>

        <li>wiceprezes Najwyższej Izby Kontroli;</li>

        <li>członek Kolegium Najwyższej Izby Kontroli;</li>

        <li>Prezes Narodowego Funduszu Zdrowia;</li>

        <li>zastępca Prezesa Narodowego Funduszu Zdrowia;</li>

        <li>Prezes Państwowego Gospodarstwa Wodnego Wody Polskie;</li>

        <li>zastępca Prezesa Państwowego Gospodarstwa Wodnego Wody Polskie;</li>

        <li>Prezes Państwowej Agencji Atomistyki;</li>

        <li>wiceprezes Państwowej Agencji Atomistyki;</li>

        <li>Prezes Polskiej Agencji Kosmicznej;</li>

        <li>wiceprezes Polskiej Agencji Kosmicznej;</li>

        <li>Prezes Polskiej Agencji Nadzoru Audytowego;</li>

        <li>Zastępca Prezesa Polskiej Agencji Nadzoru Audytowego;</li>

        <li>Prezes Polskiej Agencji Rozwoju Przedsiębiorczości;</li>

        <li>zastępca Prezesa Polskiej Agencji Rozwoju Przedsiębiorczości;</li>

        <li>Prezes Polskiej Organizacji Turystycznej;</li>

        <li>wiceprezes Polskiej Organizacji Turystycznej;</li>

        <li>Prezes Prokuratorii Generalnej Rzeczypospolitej Polskiej;</li>

        <li>wiceprezes Prokuratorii Generalnej Rzeczypospolitej Polskiej;</li>

        <li>Prezes Rządowego Centrum Legislacji;</li>

        <li>wiceprezes Rządowego Centrum Legislacji;</li>

        <li>Prezes Rządowej Agencji Rezerw Strategicznych;</li>

        <li>zastępca Prezesa Rządowej Agencji Rezerw Strategicznych;</li>

        <li>Prezes Urzędu Dozoru Technicznego;</li>

        <li>Wiceprezes Urzędu Dozoru Technicznego;</li>

        <li>Prezes Urzędu Komunikacji Elektronicznej;</li>

        <li>zastępca Prezesa Urzędu Komunikacji Elektronicznej;</li>

        <li>Prezes Urzędu Lotnictwa Cywilnego;</li>

        <li>wiceprezes Urzędu Lotnictwa Cywilnego;</li>

        <li>Prezes Urzędu Ochrony Danych Osobowych;</li>

        <li>zastępca Prezesa Urzędu Ochrony Danych Osobowych;</li>

        <li>Prezes Urzędu Ochrony Konkurencji i Konsumentów;</li>

        <li>wiceprezes Urzędu Ochrony Konkurencji i Konsumentów;</li>

        <li>Prezes Urzędu Patentowego Rzeczypospolitej Polskiej;</li>

        <li>zastępca Prezesa Urzędu Patentowego Rzeczypospolitej Polskiej;</li>

        <li>Prezes Urzędu Regulacji Energetyki;</li>

        <li>wiceprezes Urzędu Regulacji Energetyki;</li>

        <li>Prezes Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych;</li>

        <li>Wiceprezes Urzędu Rejestracji Produktów Leczniczych, Wyrobów Medycznych i Produktów Biobójczych;</li>

        <li>Prezes Urzędu Transportu Kolejowego;</li>

        <li>wiceprezes Urzędu Transportu Kolejowego;</li>

        <li>Prezes Urzędu Zamówień Publicznych;</li>

        <li>wiceprezes Urzędu Zamówień Publicznych;</li>

        <li>Prezes Wyższego Urzędu Górniczego;</li>

        <li>wiceprezes Wyższego Urzędu Górniczego;</li>

        <li>Prezes Zakładu Ubezpieczeń Społecznych;</li>

        <li>członek Zarządu Zakładu Ubezpieczeń Społecznych;</li>

        <li>Prezes Zarządu Banku Gospodarstwa Krajowego;</li>

        <li>wiceprezes Zarządu Banku Gospodarstwa Krajowego;</li>

        <li>członek Zarządu Banku Gospodarstwa Krajowego;</li>

        <li>Prezes Zarządu Narodowego Funduszu Ochrony Środowiska i Gospodarki Wodnej;</li>

        <li>zastępca Prezesa Zarządu Narodowego Funduszu Ochrony Środowiska i Gospodarki Wodnej;</li>

        <li>Prezes Zarządu Państwowego Funduszu Rehabilitacji Osób Niepełnosprawnych;</li>

        <li>zastępca Prezesa Zarządu Państwowego Funduszu Rehabilitacji Osób Niepełnosprawnych;</li>

        <li>Prokurator Generalny;</li>

        <li>zastępca Prokuratora Generalnego;</li>

        <li>Prokurator Krajowy;</li>

        <li>Przewodniczący Komisji Nadzoru Finansowego;</li>

        <li>Zastępca Przewodniczącego Komisji Nadzoru Finansowego;</li>

        <li>członek Komisji Nadzoru Finansowego;</li>

        <li>
          Przewodniczący Państwowej Komisji do spraw wyjaśniania przypadków czynności skierowanych przeciwko wolności{' '}
        </li>

        <p>seksualnej i obyczajności wobec małoletniego poniżej lat 15;</p>

        <li>
          członek Państwowej Komisji do spraw wyjaśniania przypadków czynności skierowanych przeciwko wolności
          seksualnej i obyczajności wobec małoletniego poniżej lat 15;{' '}
        </li>

        <li>Przewodniczący Krajowej Rady Radiofonii i Telewizji;</li>

        <li>zastępca Przewodniczącego Krajowej Rady Radiofonii i Telewizji;</li>

        <li>członek Krajowej Rady Radiofonii i Telewizji;</li>

        <li>Przewodniczący Państwowej Komisji Wyborczej;</li>

        <li>zastępca Przewodniczącego Państwowej Komisji Wyborczej;</li>

        <li>członek Państwowej Komisji Wyborczej;</li>

        <li>przewodniczący Rady do Spraw Uchodźców;</li>

        <li>wiceprzewodniczący Rady do Spraw Uchodźców;</li>

        <li>Przewodniczący Rady Mediów Narodowych;</li>

        <li>członek Rady Mediów Narodowych;</li>

        <li>Rzecznik Finansowy;</li>

        <li>zastępca Rzecznika Finansowego;</li>

        <li>Rzecznik Małych i Średnich Przedsiębiorców;</li>

        <li>zastępca Rzecznika Małych i Średnich Przedsiębiorców;</li>

        <li>Rzecznik Praw Dziecka;</li>

        <li>zastępca Rzecznika Praw Dziecka;</li>

        <li>Rzecznik Praw Obywatelskich;</li>

        <li>zastępca Rzecznika Praw Obywatelskich;</li>

        <li>Rzecznik Praw Pacjenta;</li>

        <li>zastępca Rzecznika Praw Pacjenta;</li>

        <li>Szef Agencji Bezpieczeństwa Wewnętrznego;</li>

        <li>zastępca Szefa Agencji Bezpieczeństwa Wewnętrznego;</li>

        <li>Szef Agencji Wywiadu;</li>

        <li>zastępca Szefa Agencji Wywiadu;</li>

        <li>Szef Biura Bezpieczeństwa Narodowego;</li>

        <li>Zastępca Szefa Biura Bezpieczeństwa Narodowego;</li>

        <li>Szef Centralnego Biura Antykorupcyjnego;</li>

        <li>Szef Krajowego Biura Wyborczego;</li>

        <li>Szef Krajowej Administracji Skarbowej;</li>

        <li>zastępca Szefa Krajowej Administracji Skarbowej;</li>

        <li>Szef Służby Cywilnej;</li>

        <li>Szef Służby Kontrwywiadu Wojskowego;</li>

        <li>zastępca Szefa Służby Kontrwywiadu Wojskowego;</li>

        <li>Szef Służby Wywiadu Wojskowego;</li>

        <li>zastępca Szefa Służby Wywiadu Wojskowego;</li>

        <li>Szef Służby Zagranicznej;</li>

        <li>Szef Urzędu do Spraw Cudzoziemców;</li>

        <li>zastępca Szefa Urzędu do Spraw Cudzoziemców;</li>

        <li>Szef Urzędu do Spraw Kombatantów i Osób Represjonowanych;</li>

        <li>zastępca Szefa Urzędu do Spraw Kombatantów i Osób Represjonowanych.</li>
      </ol>

      <p>
        <strong>
          Bliski współpracownik Osoby zajmującej eksponowane stanowiska polityczne (PEP) to osoba fizyczna:
        </strong>
      </p>

      <ul>
        <li>
          będąca beneficjentami rzeczywistymi osób prawnych, jednostek organizacyjnych nieposiadających osobowości
          prawnej lub trustów wspólnie z osobą zajmującą eksponowane stanowisko polityczne lub utrzymujące z taką osobą
          inne bliskie stosunki związane z prowadzoną działalnością gospodarczą;
        </li>
        <li>
          będąca jedynym beneficjentem rzeczywistym osób prawnych, jednostek organizacyjnych nieposiadających osobowości
          prawnej lub trustu, o których wiadomo, że zostały utworzone w celu uzyskania faktycznej korzyści przez osobę
          zajmującą eksponowane stanowisko polityczne.
        </li>
      </ul>

      <p>
        <strong>
          Pod pojęciem członka rodziny &quot;Osoby zajmującej eksponowane stanowiska polityczne&quot; (PEP)
        </strong>{' '}
        rozumie się przez to:
      </p>

      <ul>
        <li>małżonka lub osobę pozostającą we wspólnym pożyciu z osobą zajmującą eksponowane stanowisko polityczne;</li>
        <li>
          dziecko osoby zajmującej eksponowane stanowisko polityczne i jego małżonka lub osoby pozostającej we wspólnym
          pożyciu;
        </li>
        <li>rodziców osoby zajmującej eksponowane stanowisko polityczne.</li>
      </ul>
    </>
  )
}

export default DefinitionsEn
