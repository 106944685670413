import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import router from 'router'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Formik, Form } from 'formik'
import { Col, Container, Row } from 'react-bootstrap'
import { ankietaKsiegowoscKadrySchema } from 'utils/validations'
import { Label } from 'components/Forms/elements'
import { breakpoints } from 'theme/breakpoints'
import { ScrollToError } from 'components/ScrollToError'
import * as options from 'utils/config'
import Heading from 'components/Heading'
import HeadingBar from 'components/layouts/HeadingBar'
import TextInput from 'components/Forms/Inputs/TextInput'
import OtherAnswerBox from 'components/Forms/elements/OtherAnswerBox'
import Radio from 'components/Forms/Inputs/Radio'
import Checkbox from 'components/Forms/Inputs/Checkbox'
import Switch from 'components/Forms/Inputs/Switch'
import Button from 'components/Button'
import InputError from 'components/Forms/elements/Error'
import Modal from 'components/Modal'
import Loading from 'components/Loading'

const AnkietaKsiegowoscKadryView = () => {
  const { t, i18n } = useTranslation()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    setLoading(true)

    axios
      .post(
        options.endpoints.ankietaKsiekowośćKardy,
        {
          ...values,
        },
        { params: { lang: i18n.language || 'pl' } },
      )
      .then((res) => {
        console.log(res)
        navigate(router.dziekujemy)
      })
      .catch((err) => {
        console.log(err)
        setError(t('common.genericError'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Loading show={loading} />
      <Modal title={t('common.error')} show={error} onClose={() => setError(false)}>
        {error}
      </Modal>
      <HeadingBar title={t('headers.accountingAndHumanResources')} />
      <Wrapper>
        <Container>
          <Row>
            <Col md={{ span: 11, offset: 1 }}>
              <Formik
                initialValues={{
                  // Section 1
                  isNewCompany: '',
                  businessType: '',
                  entityName: '',
                  nip: '',
                  pesel: '',
                  phone: '',
                  www: '',
                  email: '',
                  // Section 2
                  zipCode: '',
                  street: '',
                  city: '',
                  buildingNumber: '',

                  // Section 4
                  businessModel: '',
                  whoDoesAccounting: '',
                  whoDoesAccountingOther: '',
                  areYouSatisfied: '',
                  areYouSatisfiedOther: '',
                  whatIsMissingFromService: '',
                  modernServiceYouCareAbout: [],
                  whyYouWantToChangeService: '',
                  formOfSettlement: '',
                  tax: '',
                  foreignIncome: false,
                  isActiveVatPayer: false,
                  vat: '',
                  vatEU: false,
                  numberOfRevenueInvoicesMonthly: '',
                  numberOfCostInvoicesMonthly: '',
                  numberOfForeingRevenueInvoicesMonthly: '',
                  numberOfForeingCostInvoicesMonthly: '',
                  doYouEmployWorkers: false,
                  numberOfEmployeesContract: '',
                  numberOfEmployeesMandate: '',
                  numberOfNonresidentEmployees: '',
                  usedProgram: '',
                  programActions: [],
                  programActionsOther: '',
                  whatSoftwareAccountantWorkOn: '',
                  // Section 5
                  costAccountant: '',
                  costHumanResources: '',
                  // Section 6
                  howYouLearnAboutUs: [],
                  howYouLearnAboutUsOther: '',
                  howYouLearnAboutUsName: '',
                  howYouLearnAboutUsPhone: '',
                  othersInformations: '',
                  regulations: false,
                }}
                validationSchema={ankietaKsiegowoscKadrySchema}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <>
                    <ScrollToError />
                    <StyledFormikForm>
                      {/* Sekcja 1 */}

                      <Section>
                        <StyledHeading>
                          {t('page.accountingAndHumanResources.basicInformationOfTheEntity')}
                        </StyledHeading>
                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.isNewCompany')}</Label>
                          <Col>
                            {options.isNewCompanyOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='isNewCompany'
                                type='radio'
                                value={opt.value}
                                label={t(`options.${opt.value}`)}
                                component={Radio}
                              />
                            ))}
                            <InputError name='isNewCompany' />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.businessType')}:</Label>
                          <Col>
                            {options.businessTypeOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='businessType'
                                type='radio'
                                value={opt.value}
                                label={t(`options.${opt.value}`)}
                                component={Radio}
                              />
                            ))}
                            <InputError name='businessType' />
                          </Col>
                        </Row>
                        <Row className='u-mb gaps'>
                          <Col md='4'>
                            <Field
                              disabled={false}
                              label={
                                ['planuje'].includes(values.isNewCompany)
                                  ? `${t('common.nameOfTheEntity')} / ${t('common.fullName')}`
                                  : `${t('common.nameOfTheEntity')}:`
                              }
                              name='entityName'
                              placeholder={t('common.placeholderFullname')}
                              component={TextInput}
                            />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label={`${t('common.nip')}:`} name='nip' component={TextInput} />
                          </Col>
                          {['jednoosobowa-dzialalnosc-gospodarcza', 'spolka-cywilna', 'spolka-jawna'].includes(
                            values.businessType,
                          ) && (
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label={`${t('common.pesel')}:`}
                                name='pesel'
                                hint={`(${t('page.accountingAndHumanResources.inCaseOfEconomicActivity')}}`}
                                component={TextInput}
                              />
                            </Col>
                          )}
                          <Col md='4'>
                            <Field
                              disabled={false}
                              label={`${t('common.phoneNumber')}:`}
                              name='phone'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label={t('common.website')} name='www' component={TextInput} />
                          </Col>
                          <Col md='4'>
                            <Field disabled={false} label={t('common.email')} name='email' component={TextInput} />
                          </Col>
                        </Row>
                      </Section>

                      {/* Sekcja 2 */}
                      <Section $marginTop>
                        <StyledHeading>{t('page.accountingAndHumanResources.addressOfTheEntity')}</StyledHeading>
                        <Row className='u-mb-s gaps'>
                          <Col md='6' lg='3'>
                            <Field
                              disabled={false}
                              label={`${t('common.zipCode')}:`}
                              name='zipCode'
                              placeholder='＿ ＿ - ＿ ＿ ＿'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field disabled={false} label={`${t('common.city')}:`} name='city' component={TextInput} />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field
                              disabled={false}
                              label={`${t('common.street')}:`}
                              name='street'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6' lg='3'>
                            <Field
                              disabled={false}
                              label={`${t('common.buildingNumber')}:`}
                              name='buildingNumber'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                      </Section>

                      {/* Sekcja 3 */}
                      <Section $marginTop>
                        <StyledHeading>{t('common.information')}</StyledHeading>
                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              label={
                                ['planuje'].includes(values.isNewCompany)
                                  ? `${t('page.accountingAndHumanResources.businessModelFuture')}:`
                                  : `${t('page.accountingAndHumanResources.businessModelNow')}:`
                              }
                              name='businessModel'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        {['nie', ''].includes(values.isNewCompany) && (
                          <>
                            <Row className='u-mb-l'>
                              <Label>{t('page.accountingAndHumanResources.whoDoesAccounting')}</Label>
                              <Col>
                                {options.whoDoesAccountingOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='whoDoesAccounting'
                                    type='radio'
                                    value={opt.value}
                                    label={t(`options.${opt.value}`)}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='whoDoesAccounting' />
                                {['inne'].includes(values.whoDoesAccounting) && (
                                  <OtherAnswerBox title='Kto?'>
                                    <Field
                                      disabled={false}
                                      placeholder={t('common.enterYourAnswer')}
                                      name='whoDoesAccountingOther'
                                      component={TextInput}
                                    />
                                  </OtherAnswerBox>
                                )}
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Label>{t('page.accountingAndHumanResources.areYouSatisfied')}</Label>
                              <Col>
                                {options.areYouSatisfiedOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='areYouSatisfied'
                                    type='radio'
                                    value={opt.value}
                                    label={t(`options.${opt.value}`)}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='areYouSatisfied' />
                                {['nie', 'inne'].includes(values.areYouSatisfied) && (
                                  <OtherAnswerBox
                                    title={values.areYouSatisfied === 'nie' ? t('common.why') : t('common.what')}
                                  >
                                    <Field
                                      disabled={false}
                                      placeholder={t('common.enterYourAnswer')}
                                      name='areYouSatisfiedOther'
                                      component={TextInput}
                                    />
                                  </OtherAnswerBox>
                                )}
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Col>
                                <Field
                                  disabled={false}
                                  label={t('page.accountingAndHumanResources.whatIsMissingFromService')}
                                  name='whatIsMissingFromService'
                                  component={TextInput}
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.modernServiceYouCareAbout')}</Label>
                          <Col>
                            {options.modernServiceYouCareAboutOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='modernServiceYouCareAbout'
                                type='checkbox'
                                value={opt.value}
                                label={t(`options.${opt.value}`)}
                                component={Checkbox}
                              />
                            ))}
                            <InputError name='modernServiceYouCareAbout' />
                            {values.modernServiceYouCareAbout.includes('inne') && (
                              <OtherAnswerBox title={t('common.whatPlural')}>
                                <Field
                                  disabled={false}
                                  placeholder={t('common.enterYourAnswer')}
                                  name='modernServiceYouCareAboutOther'
                                  component={TextInput}
                                />
                              </OtherAnswerBox>
                            )}
                          </Col>
                        </Row>
                        {['nie', ''].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Col>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.whyYouWantToChangeService')}
                                name='whyYouWantToChangeService'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.formOfSettlement')}</Label>
                          <Col>
                            {options.formOfSettlementOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='formOfSettlement'
                                type='radio'
                                value={opt.value}
                                label={t(`options.${opt.value}`)}
                                component={Radio}
                              />
                            ))}
                            <InputError name='formOfSettlement' />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Label>{t('page.accountingAndHumanResources.tax')}</Label>
                            <Col>
                              {options.taxOptions.map((opt) => (
                                <Field
                                  key={opt.value}
                                  disabled={false}
                                  name='tax'
                                  type='radio'
                                  value={opt.value}
                                  label={t(`options.${opt.value}`)}
                                  component={Radio}
                                />
                              ))}
                              <InputError name='tax' />
                            </Col>
                          </Row>
                        )}

                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.foreignIncome')}</Label>
                          <Col>
                            <Field disabled={false} name='foreignIncome' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          {['planuje'].includes(values.isNewCompany) ? (
                            <Label>{t('page.accountingAndHumanResources.isActiveVatPayerFuture')}</Label>
                          ) : (
                            <Label>{t('page.accountingAndHumanResources.isActiveVatPayerNow')}</Label>
                          )}
                          <Col>
                            <Field disabled={false} name='isActiveVatPayer' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && values.isActiveVatPayer && (
                          <>
                            <Row className='u-mb-l'>
                              <Label>{t('page.accountingAndHumanResources.vat')}</Label>
                              <Col>
                                {options.vatOptions.map((opt) => (
                                  <Field
                                    key={opt.value}
                                    disabled={false}
                                    name='vat'
                                    type='radio'
                                    value={opt.value}
                                    label={t(`options.${opt.value}`)}
                                    component={Radio}
                                  />
                                ))}
                                <InputError name='vat' />
                              </Col>
                            </Row>
                            <Row className='u-mb-l'>
                              <Label>{t('page.accountingAndHumanResources.vatEU')}</Label>
                              <Col>
                                <Field disabled={false} name='vatEU' type='checkbox' component={Switch} />
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row className='u-mb-l gaps'>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label={t('page.accountingAndHumanResources.numberOfRevenueInvoicesMonthly')}
                              name='numberOfRevenueInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label={t('page.accountingAndHumanResources.numberOfCostInvoicesMonthly')}
                              name='numberOfCostInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label={t('page.accountingAndHumanResources.numberOfForeingRevenueInvoicesMonthly')}
                              name='numberOfForeingRevenueInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                          <Col md='6'>
                            <Field
                              disabled={false}
                              label={t('page.accountingAndHumanResources.numberOfForeingCostInvoicesMonthly')}
                              name='numberOfForeingCostInvoicesMonthly'
                              type='number'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          {['planuje'].includes(values.isNewCompany) ? (
                            <Label>{t('page.accountingAndHumanResources.doYouEmployWorkersFuture')}</Label>
                          ) : (
                            <Label>{t('page.accountingAndHumanResources.doYouEmployWorkersNow')}</Label>
                          )}
                          <Col>
                            <Field disabled={false} name='doYouEmployWorkers' type='checkbox' component={Switch} />
                          </Col>
                        </Row>
                        {!['planuje'].includes(values.isNewCompany) && values.doYouEmployWorkers && (
                          <Row className='u-mb-l gaps'>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.numberOfEmployeesContract')}
                                name='numberOfEmployeesContract'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.numberOfEmployeesMandate')}
                                name='numberOfEmployeesMandate'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                            <Col md='4'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.numberOfNonresidentEmployees')}
                                name='numberOfNonresidentEmployees'
                                type='number'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Col>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.usedProgram')}
                                name='usedProgram'
                                component={TextInput}
                              />
                            </Col>
                          </Row>
                        )}
                        {!['planuje'].includes(values.isNewCompany) && (
                          <Row className='u-mb-l'>
                            <Label>{t('page.accountingAndHumanResources.programActions')}</Label>
                            <Col>
                              {options.programActions.map((opt) => (
                                <Field
                                  key={opt.value}
                                  disabled={false}
                                  name='programActions'
                                  type='checkbox'
                                  value={opt.value}
                                  label={t(`options.${opt.value}`)}
                                  component={Checkbox}
                                  block
                                />
                              ))}
                              <InputError name='programActions' />
                              {values.programActions.includes('inne') && (
                                <OtherAnswerBox title={t('common.whatPlural')}>
                                  <Field
                                    disabled={false}
                                    placeholder={t('common.enterYourAnswer')}
                                    name='programActionsOther'
                                    component={TextInput}
                                  />
                                </OtherAnswerBox>
                              )}
                            </Col>
                          </Row>
                        )}

                        {['nie'].includes(values.isNewCompany) && (
                          <>
                            <Row className='u-mb-l'>
                              <Col>
                                <Field
                                  disabled={false}
                                  label={t('page.accountingAndHumanResources.whatSoftwareAccountantWorkOn')}
                                  name='whatSoftwareAccountantWorkOn'
                                  component={TextInput}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Section>

                      {/* Sekcja 4 */}
                      {['nie'].includes(values.isNewCompany) && (
                        <Section $marginTop>
                          <StyledHeading>{t('page.accountingAndHumanResources.costAccountantHeading')}</StyledHeading>
                          <Row className='u-mb-l gaps'>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.costAccountant')}
                                name='costAccountant'
                                component={TextInput}
                                type='number'
                              />
                            </Col>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.costHumanResources')}
                                name='costHumanResources'
                                component={TextInput}
                                type='number'
                              />
                            </Col>
                          </Row>
                        </Section>
                      )}
                      {/* Sekcja 5 */}
                      <Section $marginTop>
                        <StyledHeading>{t('page.accountingAndHumanResources.summary')}</StyledHeading>
                        <Row className='u-mb-l'>
                          <Label>{t('page.accountingAndHumanResources.howYouLearnAboutUs')}</Label>
                          <Col>
                            {options.howYouLearnAboutUsOptions.map((opt) => (
                              <Field
                                key={opt.value}
                                disabled={false}
                                name='howYouLearnAboutUs'
                                type='checkbox'
                                value={opt.value}
                                label={t(`options.${opt.value}`)}
                                component={Checkbox}
                              />
                            ))}
                            <InputError name='howYouLearnAboutUs' />
                            {values.howYouLearnAboutUs.includes('inne') && (
                              <OtherAnswerBox title={t('common.fromWhere')}>
                                <Field
                                  disabled={false}
                                  placeholder={t('common.enterYourAnswer')}
                                  name='howYouLearnAboutUsOther'
                                  component={TextInput}
                                />
                              </OtherAnswerBox>
                            )}
                          </Col>
                        </Row>
                        {values.howYouLearnAboutUs.includes('z-polecenia') && (
                          <Row className='u-mb-l gaps'>
                            <Label>{t('page.accountingAndHumanResources.howYouLearnAboutUsNameHeading')}</Label>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.howYouLearnAboutUsName')}
                                name='howYouLearnAboutUsName'
                                component={TextInput}
                                type='text'
                              />
                            </Col>
                            <Col md='6'>
                              <Field
                                disabled={false}
                                label={t('page.accountingAndHumanResources.howYouLearnAboutUsPhone')}
                                name='howYouLearnAboutUsPhone'
                                component={TextInput}
                                type='text'
                              />
                            </Col>
                          </Row>
                        )}
                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              label={t('page.accountingAndHumanResources.othersInformations')}
                              name='othersInformations'
                              component={TextInput}
                            />
                          </Col>
                        </Row>
                        <Row className='u-mb-l'>
                          <Col>
                            <Field
                              disabled={false}
                              name='regulations'
                              type='checkbox'
                              label={
                                <>
                                  {i18n.language === 'pl' && (
                                    <>
                                      <span>Oświadczam, że zapoznałem/-am się z </span>{' '}
                                      <Link className='link' target='_blank' to={router.politykaPrywatnosci}>
                                        Polityką prywatności
                                      </Link>
                                      <span>.</span>
                                    </>
                                  )}
                                  {i18n.language === 'en' && (
                                    <>
                                      <span>I declare that I have read the </span>{' '}
                                      <Link className='link' target='_blank' to={router.politykaPrywatnosci}>
                                        Privacy Policy
                                      </Link>
                                      <span>.</span>
                                    </>
                                  )}
                                </>
                              }
                              component={Checkbox}
                            />
                            <InputError name='regulations' />
                          </Col>
                        </Row>
                      </Section>
                      <ActionsWrapper>
                        <Button type='submit'>{t('buttons.sendSurvey')}</Button>
                      </ActionsWrapper>
                    </StyledFormikForm>
                  </>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default AnkietaKsiegowoscKadryView

const Wrapper = styled.section`
  padding-top: 6rem;
  padding-bottom: 8rem;
  background-color: ${(props) => props.theme.colors.bg};

  @media ${breakpoints.max.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

const StyledFormikForm = styled(Form)``
const StyledHeading = styled(Heading)``

const Section = styled.section`
  position: relative;

  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: ${(props) => props.theme.gaps.xl};
    `};

  .gaps {
    row-gap: 1.5rem;
  }

  @media ${breakpoints.min.md} {
    &:not(:last-of-type):before {
      content: '';
      display: block;
      position: absolute;
      top: 3rem;
      bottom: -4rem;
      left: -10rem;
      width: 1px;
      background-color: ${(props) => props.theme.colors.border};
    }

    ${StyledHeading} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0rem;
        left: -10rem;
        width: 2rem;
        height: 2rem;
        border: 2px solid ${(props) => props.theme.colors.border};
        padding: 2px;
        background-clip: content-box;
        border-radius: 50%;
        transform: translateX(-50%);
        background-color: ${(props) => props.theme.colors.blue};
      }
    }
  }
`

const ActionsWrapper = styled.div`
  margin-top: ${(props) => props.theme.gaps.xxl};
`
