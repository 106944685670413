import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker'
import styled, { css } from 'styled-components'
import { Error, FormControl } from '../elements'
import { placeholder } from 'theme/mixins'
import * as typo from 'theme/typography'

const DocumentDatePicker = ({
  label = '',
  hint = '',
  field,
  form,
  documentType = false,
  $minWidth = false,
  ...props
}) => {
  const onChangeHandler = (date) => {
    form.setFieldTouched(field.name)
    if (date) form.setFieldValue(field.name, date)
    else form.setFieldValue(field.name, '')
  }
  const { touched, errors } = form

  return (
    <Wrapper>
      <StyledDocumentDatePicker
        $valid={Boolean(!form.errors[field.name] || props.disabled)}
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        yearPlaceholder='YYYY'
        showLeadingZeros
        onChange={onChangeHandler}
        value={field.value}
        {...props}
        style={{ width: `${field.value.length + 1}ch`, minWidth: $minWidth ? $minWidth : '25ch' }}
      />
      {touched[field.name] && errors[field.name] && <CustomError>{errors[field.name]}</CustomError>}
      {label && (
        <p className='t-paragraph t-paragraph--s' style={{ marginTop: '0.5rem' }}>
          {label}
        </p>
      )}
    </Wrapper>
  )
}

export default DocumentDatePicker

DocumentDatePicker.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  documentType: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  $minWidth: PropTypes.string,
}

const Wrapper = styled(FormControl)`
  position: relative;
`

const CustomError = styled(Error)`
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 40rem;
  transform: translateY(-120%);
  margin: 0;
  text-align: left;
`

const StyledDocumentDatePicker = styled(DatePicker)`
  background-color: ${(props) => props.theme.colors.lightBlueBg};
  font-family: monospace;
  display: inline-block;
  min-width: 15ch;

  input {
    ${typo.Input}
    min-height: 2.2rem;
  }

  &:active,
  &:focus {
    outline: 1px dashed ${(props) => props.theme.colors.red};
    outline-offset: 2px;
  }

  .react-date-picker__wrapper {
    border: none;
    width: 100%;
  }

  .react-date-picker__inputGroup {
    padding: 0 1rem;
  }

  .react-date-picker__button {
    display: none;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  .react-date-picker__inputGroup__leadingZero {
    ${typo.Input}
  }

  .react-date-picker__clear-button {
    display: none;
  }
`
