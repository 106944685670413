import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Route, Routes, Navigate, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import routes from 'router'
import Footer from 'components/layouts/Footer'
import Header from 'components/layouts/Header'
import AnkietaKsiegowoscKadryView from 'views/AnkietaKsiegowoscKadryView'
import PodziekowanieView from 'views/PodziekowanieView'
import NoMatchView from 'views/NoMatchView'
import PolitypaPrywatnościView from 'views/PolitykaPrywatnościView'
import OswiadczeniePepView from 'views/OswiadczeniePepView'
import PodziekowanieOswiadczenieView from 'views/PodziekowanieOswiadzczenieView'
import { useTranslation } from 'react-i18next'

function App() {
  let { i18n } = useTranslation()
  let [searchParams] = useSearchParams()
  const lang = searchParams.get('lang')

  useEffect(() => {
    if (!lang) return
    if (lang != i18n.language) i18n.changeLanguage(lang)
  }, [lang])

  return (
    <>
      <Header />
      <Main>
        <Routes>
          <Route index element={<Navigate to={routes.ankietaKsiegowoscKadry} />} />

          <Route path={routes.ankieta}>
            <Route index element={<Navigate to={routes.ankietaKsiegowoscKadry} />} />
            <Route path={routes.ankietaKsiegowoscKadry} element={<AnkietaKsiegowoscKadryView />} />
          </Route>

          <Route path={routes.oswiadczenia}>
            <Route index element={<Navigate to={routes.oswiadczeniePep} />} />
            <Route path={routes.oswiadczeniePep} element={<OswiadczeniePepView />} />
          </Route>

          <Route path={routes.politykaPrywatnosci} element={<PolitypaPrywatnościView />} />
          <Route path={routes.dziekujemy} element={<PodziekowanieView />} />
          <Route path={routes.dziekujemyOswiadczenie} element={<PodziekowanieOswiadczenieView />} />
          <Route path={routes.noMatch} element={<NoMatchView />} />
        </Routes>
      </Main>
      <Footer />
    </>
  )
}

export default App

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`
