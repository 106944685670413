import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { breakpoints } from 'theme/breakpoints'
import { ParagraphM, ParagraphXL } from 'theme/typography'

const LangSwitcher = () => {
  const { i18n } = useTranslation()

  const changeLanguageHandler = (lang) => i18n.changeLanguage(lang)

  return (
    <LangSwitcherWrapper>
      <LangItem $active={i18n.language === 'pl'} onClick={() => changeLanguageHandler('pl')}>
        PL
      </LangItem>
      <span>|</span>
      <LangItem $active={i18n.language === 'en'} onClick={() => changeLanguageHandler('en')}>
        EN
      </LangItem>
    </LangSwitcherWrapper>
  )
}

export default LangSwitcher

const LangSwitcherWrapper = styled.div`
  ${ParagraphXL}

  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media ${breakpoints.min.sm} {
    ${ParagraphM}

    position: absolute;
    top: 0;
    right: 0;
  }
`

const LangItem = styled.p`
  cursor: pointer;
  margin-bottom: 0;

  ${(props) =>
    props.$active &&
    css`
      color: ${(props) => props.theme.colors.blue};
    `}

  &:hover {
    text-decoration: underline;
  }
`
